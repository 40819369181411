import React from "react";
import StyledMostViewedProducts from "./MostViewedProducts.styles";
import Module from "../../components/Module/Module";
import { ModuleVariantEnum } from "../../components/Module/Module.types";
import { OakProductDTO } from "../../types/dto/product";
import Carousel from "../../components/Carousel/Carousel";
import ProductCard from "../../components/ProductCard/ProductCard";
import { getRandomItems } from "../../utils/utils";
import useMostViewedProduct from "./useMostViewedProducts";
import useWindowSize from "../../utils/hooks/useWindowSize";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { SectionTitleVariant } from "../../components/SectionTitle/SectionTitle.types";

const MostViewedProducts = (products: OakProductDTO[]) => {
  const { primary: primaryModule } = ModuleVariantEnum;
  const { primary: primarySectionTitle } = SectionTitleVariant;

  const mostViewedProducts = getRandomItems(products, 12);

  const [width] = useWindowSize();

  const { groupedCards } = useMostViewedProduct(width, mostViewedProducts);

  return (
    <Module variant={primaryModule}>
      <StyledMostViewedProducts>
        <SectionTitle variant={primarySectionTitle}>
          FREQUENTLY CHOSEN
        </SectionTitle>
        <div className="most-viewed-products__slider-container">
          <Carousel
            variant="primary"
            items={groupedCards.map((slider, idx) => (
              <div
                key={idx}
                className="most-viewed-products__single-slider-container"
              >
                {slider.map((card) => (
                  <ProductCard
                    key={card.node.name}
                    name={card.node.name}
                    image={card.node.images[0]}
                  />
                ))}
              </div>
            ))}
          />
        </div>
      </StyledMostViewedProducts>
    </Module>
  );
};

export default MostViewedProducts;
