import styled from "styled-components";
import BreakPointsEnum from "../../types/global/global";

const StyledProduct = styled.div`
    display: flex;
    flex-direction: row;
    gap: 75px;
    margin-bottom: 100px;

    .gatsby-image-wrapper{
        width: 100%;
    }

    .product__ {
        &image-container {
            position: relative;
            flex: 45%;
            height: 678px;
            > div {
                height: 678px;
                max-height: 678px;
            }

            img {
                width: 100%;
            }
        }
        &description-container {
            flex: 55%;
        }
        &dropdown-container {
            width: 250px;
        }
    }

    @media only screen and (max-width: ${BreakPointsEnum._mobile}) {
        flex-direction: column;

        .product__ {
            &image-container {
                height: unset;
                width: 100%;
                > div {
                    height: unset;
                    max-height: unset;
                }
            }
        }
    }
`;

export default StyledProduct;
