import React from "react";
import Layout from "../components/Layout/Layout";
import Product from "../modules/Product/Product";
import MostViewedProducts from "../modules/MostViewedProducts/MostViewedProducts";
import Head from "../components/Head/Head";

const OakProduct = ({ pageContext }) => {
  const { node, products } = pageContext;

  return (
    <Layout>
      <Head
        description={node.metaDescription}
        title={node.name}
        keywords={node.keywords}
        externalScript=""
        metaImage=""
      />
      <Product {...node} />
      <MostViewedProducts {...products} />
    </Layout>
  );
};

export default OakProduct;
