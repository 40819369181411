import { useEffect, useState } from "react";
import { groupArrayToSmallerByValue } from "../../utils/utils";
import BreakPointsEnum from "../../types/global/global";

const useMostViewedProduct = (browserWidth: number, cards: any[]) => {
    const [groupedCards, setGroupedCards] = useState<any[]>([]);
    useEffect(() => {
        let groupedArray = [];
        if (browserWidth > parseInt(BreakPointsEnum._wide.replace(/\D/g, ""))) {
            groupedArray = groupArrayToSmallerByValue([...cards], 4);
        } else if (browserWidth > 1020) {
            groupedArray = groupArrayToSmallerByValue([...cards], 3);
        } else {
            groupedArray = groupArrayToSmallerByValue([...cards], 2);
        }

        setGroupedCards(groupedArray);
    }, [browserWidth]);

    return { groupedCards };
};

export default useMostViewedProduct;
