import React, { useState } from "react";
import StyledProduct from "./Product.styles";
import Module from "../../components/Module/Module";
import { ModuleVariantEnum } from "../../components/Module/Module.types";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import DropdownInput from "../../components/Dropdown/DropdownInput";
import { DropdownInputIdEnum } from "../../components/Dropdown/DropdownInput.types";
import { OakProductDTO } from "../../types/dto/product";
import { GatsbyImage } from "gatsby-plugin-image";
import Text from "../../components/Text/Text";
import { TextVariantEnum } from "../../components/Text/Text.types";
import { nullHandler } from "../../utils/utils";
import Button from "../../components/Button/Button";
import { ButtonVariantEnum } from "../../components/Button/Button.types";
import { useBasketContext } from "../../context/BasketContext/BasketContext";
import { useTooltipContext } from "../../context/TooltipContext/TooltipContext";
import { SectionTitleVariant } from "../../components/SectionTitle/SectionTitle.types";

const Product = ({
    id,
    name,
    images,
    description: { description },
    size,
    price,
    material,
    category,
    featuresDescription: { featuresDescription },
    keywords,
    metaDescription,
}: OakProductDTO) => {
    const { primary } = ModuleVariantEnum;
    const { products } = DropdownInputIdEnum;
    const { primary: btnPrimary } = ButtonVariantEnum;
    const { primary: textPrimary } = TextVariantEnum;
    const { primary: primaryectionTitle } = SectionTitleVariant;

    const [selectedSize, setSelectedSize] = useState(size ? size[0] : null);

    const { addItem } = useBasketContext();
    const { showTooltip } = useTooltipContext();

    const onClickAddItemHandler = () => {
        addItem(id, selectedSize);
    };

    const changeSizeHandler = (e) => {
        setSelectedSize(e.value);
    };

    const sizes = nullHandler(size);

    return (
        <Module variant={primary}>
            <StyledProduct>
                <div className="product__image-container">
                    <GatsbyImage
                        image={images[0].gatsbyImageData}
                        alt={`${name} image`}
                    />
                </div>
                <div className="product__description-container">
                    <SectionTitle variant={primaryectionTitle}>
                        {name}
                    </SectionTitle>
                    <Text variant={textPrimary}>{description}</Text>
                    {material && (
                        <Text variant={textPrimary}>Material: {material}</Text>
                    )}
                    <Text variant={textPrimary}>{featuresDescription}</Text>

                    {sizes && (
                        <div className="product__dropdown-container">
                            <DropdownInput
                                id={products}
                                options={size.map((el) => {
                                    return {
                                        value: el,
                                        label: el,
                                    };
                                })}
                                onChange={changeSizeHandler}
                            />
                        </div>
                    )}
                    <Button
                        label="Add to basket"
                        variant={btnPrimary}
                        onClick={onClickAddItemHandler}
                    />
                </div>
            </StyledProduct>
        </Module>
    );
};

export default Product;
