import styled from "styled-components";
import BreakPointsEnum from "../../types/global/global";

const StyledMostViewedProducts = styled.div`
    margin-bottom: 100px;
    width: 100%;

    .most-viewed-products__ {
        &slider-container {
            margin-top: 50px;
        }

        &single-slider-container {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            gap: 15px 15px;
        }
    }

    @media only screen and (max-width: ${BreakPointsEnum._wide}) {
        .most-viewed-products__ {
            &single-slider-container {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
    @media only screen and (max-width: 1020px) {
        .most-viewed-products__ {
            &single-slider-container {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
    @media only screen and (max-width: ${BreakPointsEnum._mobile}) {
        .most-viewed-products__ {
            &single-slider-container {
                width: 100%;
            }
        }
    }
`;

export default StyledMostViewedProducts;
